import React, { Component } from 'react';
import styled from '@emotion/styled';
import CountUp, { startAnimation } from 'react-countup';

import VisibilitySensor from 'react-visibility-sensor';

const NumberBlockContainer = styled.div`
  font-family: 'Montserrat', sans-serif;
  color: ${props => props.color};
  border-width: 0px;
  border-color: #fff;
  border-top-width: 2px;
  border-style: ${props => props.borderStyle};
  border-width: 0px;
  border-top-width: 2px;
  margin-left: 0px;
  margin-right: 10px;
  border-color: ${props => props.borderColor};
  margin-bottom: 30px;
  @media (max-width: 600px) {
    margin-top: 0px;
  }
`;

const MainNumber = styled.div`
  font-style: normal;
  font-weight: 800;
  letter-spacing: 1.5px;
  font-size: ${props => props.statFontSize};
  line-height: ${props => props.statFontLineHeight};
  @media (max-width: 1050px) {
    font-size: ${props => props.statTabletFontSize};
    line-height: ${props => props.statTabletLineHeight};
  }
  @media (max-width: 820px) {
    font-size: ${props => props.statMobileFontSize};
    line-height: ${props => props.statMobileLineHeight};
  }
`;

const NumberType = styled.div`
  font-size: 26px;
  margin-left: 2.5px;
  @media (max-width: 820px) {
    font-size: 14px;
  }
`;

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 1050px) {
    margin-bottom: 10px;
  }
`;

const NumberStat = styled.div`
  font-weight: ${props => props.fontWeight};
  max-width: 200px;
  @media (max-width: 1050px) {
    font-size: 14px;
  }
  @media (max-width: 820px) {
    max-width: 125px;
    font-size: 12px;
  }
`;

export class NumberBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    let decimalNumbers = 0;
    if (this.props.number) {
      if (this.props.number.indexOf('.') > -1) {
        decimalNumbers = this.props.number.match(new RegExp('\\.', 'g')).length;
      }
    }
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={0}
      >
        <NumberBlockContainer
          color={this.props.color}
          padding={this.props.padding}
          borderStyle={this.props.borderStyle}
          margin={this.props.margin}
          minWidth={parseInt(this.props.number) > 99 ? '230px' : '160px'}
          borderColor={this.props.borderColor}
        >
          <NumberContainer>
            <MainNumber
              statFontSize={this.props.statFontSize}
              statMobileFontSize={
                this.props.statMobileFontSize
                  ? this.props.statMobileFontSize
                  : '50px'
              }
              statMobileLineHeight={
                this.props.statMobileLineHeight
                  ? this.props.statMobileLineHeight
                  : '50px'
              }
              statTabletFontSize={
                this.props.statTabletFontSize
                  ? this.props.statTabletFontSize
                  : '70px'
              }
              statTabletLineHeight={
                this.props.statTabletLineHeight
                  ? this.props.statTabletLineHeight
                  : '70px'
              }
              statFontLineHeight={this.props.statFontLineHeight}
            >
              {this.state.visible ? (
                <React.Fragment>
                  {this.props.number ? (
                    <React.Fragment>
                      <CountUp
                        decimals={decimalNumbers}
                        end={Number(this.props.number)}
                      />
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              ) : (
                '0'
              )}
            </MainNumber>
            <NumberType>{this.props.statType}</NumberType>
          </NumberContainer>
          <NumberStat fontWeight={this.props.fontWeight}>
            {this.props.numberSubtitle}
          </NumberStat>
        </NumberBlockContainer>
      </VisibilitySensor>
    );
  }
}

export default NumberBlock;
